<template lang='pug'>
.font-24.text-primary.text-weight-medium.q-my-xl
    | Список товаров по алфавиту
    .text-primary.text-weight-medium.q-my-xl
        q-list.row.no-wrap
            q-item.top.cursor-pointer(
            v-for='(letter,index) of alphabet'
            :key='index'
            :class="{'activeLetter': activeLetter === letter}"
            @click="$emit('checkActiveLetter',letter)"
            )
                q-item-section.font-20(
                    @click="$emit('checkActiveLetter',letter)"
                    )
                    | {{letter}}
q-list.products.row.col-12(v-if='activeLetter' )
    q-item.col-3(
        v-for='(item,index) of filteredList'
        :key='index'
    )
        q-item-section.product.font-14
            router-link.text-primary(
                :to="`/search?query=${item.name}&limit=20`"
                style='text-decoration: none'
            )
                | {{item.name}}
//q-btn(
//    v-if="currentPage < totalPages"
//    @click="nextPage")
//  |Показать еще

</template>

<script>
import { useQuasar, useMeta } from 'quasar';

export default {
    name: 'alphabetDesktop',
    props:{
        alphabet: {
            type: Array,
        },
        activeLetter:{
            type:String,
        },
        filteredList:{
            type:Array,
        },
        currentPage:{
            type:Number,
        },
        totalPages:{
            type: Number,
        },
    },
    emits:['checkActiveLetter','changePage'],
    setup(props,{ emit }){
        const nextPage = (page)=>{
            let newPage = page;
            emit('changePage',newPage++);
        };
        return{
            nextPage,
        };
    },
    

};
</script>

<style scoped lang='scss'>
.q-item.top {
    padding: 0 10px;
    height: min-content;
    min-height: 5px;
    flex-grow: 1;
    text-align: center;
    &:hover{
        border-radius: 10px;
        background: #FFFFFF;
        color: #0CA551 !important;
        outline: 1px solid #0CA551 ;
    }
    &.activeLetter{
        border-radius: 10px;
        background: #0CA551;
        color: #FFFFFF !important;
    }
}
.q-list{
    min-height: min-content;
    & > .q-item{
        & a:hover{
            color: #0CA551!important;
            text-decoration: underline!important;
        }
    }
}
.activeLetter{
    border-radius: 10px;
    background: #0CA551;
    color: #FFFFFF !important;
}
.activeProduct{
    color: #0CA551 !important;
}
</style>
